import { SvgProps } from '../SvgRendererTypes';

export const ProProtection = ({ ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" {...props}>
      <title>Professional Protection</title>
      <g>
        <g id="Font_Icons">
          <polygon points="14 10 14 2 10 2 10 10 2 10 2 14 10 14 10 22 14 22 14 14 22 14 22 10 14 10" />
        </g>
      </g>
    </svg>
  );
};
