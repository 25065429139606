import { SvgProps } from '../SvgRendererTypes';

export const AlarmProMonitoring = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" {...props}>
      <title>Alarm Pro Monitoring</title>
      <g>
        <g id="Font_Icons">
          <g>
            <path
              className={fillColor}
              d="M15.42,15h-.05v-2.12c0-1.97-1.51-3.56-3.37-3.56s-3.37,1.59-3.37,3.56v2.12h-.05c-.72,0-1.3.57-1.3,1.28v.44c0,.71.58,1.28,1.3,1.28h6.85c.72,0,1.3-.57,1.3-1.28v-.44c0-.71-.58-1.28-1.3-1.28ZM9.63,12.88c0-1.39,1.06-2.51,2.37-2.51s2.37,1.13,2.37,2.51v2.08h-4.74v-2.08ZM15.72,16.72c0,.16-.13.29-.29.29h-6.85c-.16,0-.29-.13-.29-.29v-.44c0-.16.13-.29.29-.29h.05s6.74,0,6.74,0h0s.05,0,.05,0c.16,0,.29.13.29.29v.44Z"
            />
            <path
              className={fillColor}
              d="M12,8.29c.28,0,.5-.22.5-.5v-2.02c0-.28-.22-.5-.5-.5s-.5.22-.5.5v2.02c0,.28.22.5.5.5Z"
            />
            <path
              className={fillColor}
              d="M15.01,9c.13,0,.26-.05.35-.15l1.39-1.39c.2-.2.2-.51,0-.71s-.51-.2-.71,0l-1.39,1.39c-.2.2-.2.51,0,.71.1.1.23.15.35.15Z"
            />
            <path
              className={fillColor}
              d="M18.07,11.18h-1.69c-.28,0-.5.22-.5.5s.22.5.5.5h1.69c.28,0,.5-.22.5-.5s-.22-.5-.5-.5Z"
            />
            <path
              className={fillColor}
              d="M8.12,11.68c0-.28-.22-.5-.5-.5h-1.69c-.28,0-.5.22-.5.5s.22.5.5.5h1.69c.28,0,.5-.22.5-.5Z"
            />
            <path
              className={fillColor}
              d="M8.46,9.02c.1.1.23.15.35.15s.26-.05.35-.15c.2-.2.2-.51,0-.71l-1.39-1.39c-.2-.2-.51-.2-.71,0-.2.2-.2.51,0,.71l1.39,1.39Z"
            />
            <path
              className={fillColor}
              d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10,10-4.48,10-10S17.52,2,12,2ZM12,21c-4.96,0-9-4.04-9-9S7.04,3,12,3s9,4.04,9,9-4.04,9-9,9Z"
            />
            <path
              className={fillColor}
              d="M11.62,11.04c-.43.11-1.27.6-1.34,1.66-.02.28.19.51.47.53.01,0,.02,0,.03,0,.26,0,.48-.2.5-.47.04-.58.53-.74.59-.75.27-.07.43-.34.36-.61-.07-.27-.34-.44-.61-.36Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
