import { SvgProps } from '../SvgRendererTypes';

export const PersonDetectionFill = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg
      version="1.1"
      id="Font_Icons"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <title>Person Alerts</title>
      <g>
        <path
          className={fillColor}
          d="M2.5,7C2.78,7,3,6.78,3,6.5h0V3h3.5v0C6.78,3,7,2.78,7,2.5C7,2.22,6.78,2,6.5,2h-4C2.22,2,2,2.22,2,2.5v4
			C2,6.78,2.22,7,2.5,7z"
        />
        <path
          className={fillColor}
          d="M21.5,2h-4C17.22,2,17,2.22,17,2.5S17.22,3,17.5,3v0H21v3.5h0C21,6.78,21.22,7,21.5,7S22,6.78,22,6.5v-4
			C22,2.22,21.78,2,21.5,2z"
        />
        <path
          className={fillColor}
          d="M21.5,17c-0.28,0-0.5,0.22-0.5,0.5h0V21h-3.5v0c-0.28,0-0.5,0.22-0.5,0.5c0,0.28,0.22,0.5,0.5,0.5h4
			c0.28,0,0.5-0.22,0.5-0.5v-4C22,17.22,21.78,17,21.5,17z"
        />
        <path
          className={fillColor}
          d="M6.5,21L6.5,21L3,21v-3.5h0C3,17.22,2.78,17,2.5,17S2,17.22,2,17.5v4C2,21.78,2.22,22,2.5,22h4C6.78,22,7,21.78,7,21.5
			S6.78,21,6.5,21z"
        />
        <circle cx="15.77" cy="8.2" r="3.25" />
        <path
          className={fillColor}
          d="M10.82,9.33H5.48c-0.28,0-0.5,0.22-0.5,0.5s0.22,0.5,0.5,0.5h5.33c0.28,0,0.5-0.22,0.5-0.5S11.09,9.33,10.82,9.33z"
        />
        <path
          className={fillColor}
          d="M6.77,6.95c0,0.28,0.22,0.5,0.5,0.5h3.54c0.28,0,0.5-0.22,0.5-0.5s-0.22-0.5-0.5-0.5H7.27C7,6.45,6.77,6.67,6.77,6.95z"
        />
        <path
          className={fillColor}
          d="M10.01,12.78c0-0.28-0.22-0.5-0.5-0.5H6.27c-0.28,0-0.5,0.22-0.5,0.5s0.22,0.5,0.5,0.5h3.24
			C9.79,13.28,10.01,13.05,10.01,12.78z"
        />
        <path
          className={fillColor}
          d="M18.11,15.25c0.12-0.99-0.12-1.88-0.67-2.5c-0.5-0.56-1.23-0.87-2.07-0.87h-0.55c-1.49,0-3.73,0-4.3,3.22l-0.92,3.95h7.62
			L18.11,15.25z"
        />
      </g>
    </svg>
  );
};
