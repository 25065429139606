import { SvgProps } from '../SvgRendererTypes';

export const SOS = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg
      data-name="Font Icons"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 24 24"
      {...props}
    >
      <title>SOS Emergency Response</title>
      <path
        className={fillColor}
        d="M7.15,11.7l-.69-.22c-.65-.21-.78-.44-.78-.7,0-.33.18-.69.97-.69.51,0,1.22.19,1.62.58h.03v-1c-.27-.25-1.02-.43-1.53-.43-1.34,0-2.06.7-2.06,1.61,0,.7.37,1.23,1.25,1.5l.63.19c.69.2,1,.37,1,.74,0,.51-.39.69-1.04.69-.55,0-1.33-.19-1.88-.71h-.03v1.02c.43.37,1.22.56,1.86.56,1.48,0,2.05-.79,2.05-1.64,0-.74-.37-1.18-1.41-1.5Z"
      />
      <path
        className={fillColor}
        d="M17.91,11.7l-.69-.22c-.65-.21-.78-.44-.78-.7,0-.33.18-.69.97-.69.51,0,1.22.19,1.62.58h.03v-1c-.27-.25-1.02-.43-1.53-.43-1.34,0-2.06.7-2.06,1.61,0,.7.37,1.23,1.25,1.5l.63.19c.69.2,1,.37,1,.74,0,.51-.39.69-1.04.69-.55,0-1.33-.19-1.88-.71h-.03v1.02c.43.37,1.22.56,1.86.56,1.48,0,2.05-.79,2.05-1.64,0-.74-.37-1.18-1.41-1.5Z"
      />
      <path
        className={fillColor}
        d="M12.01,9.24c-1.57,0-2.8,1.21-2.8,2.8s1.16,2.8,2.77,2.8,2.79-1.19,2.79-2.8-1.11-2.8-2.76-2.8ZM12.01,13.96c-1.02,0-1.82-.74-1.82-1.92,0-1.11.77-1.93,1.81-1.93,1.1,0,1.8.84,1.8,1.93s-.71,1.92-1.79,1.92Z"
      />
      <path
        className={fillColor}
        d="M20.08,6H3.92c-1.06,0-1.92.86-1.92,1.92v8.16c0,1.06.86,1.92,1.92,1.92h16.16c1.06,0,1.92-.86,1.92-1.92V7.92c0-1.06-.86-1.92-1.92-1.92ZM21,16.08c0,.51-.41.92-.92.92H3.92c-.51,0-.92-.41-.92-.92V7.92c0-.51.41-.92.92-.92h16.16c.51,0,.92.41.92.92v8.16Z"
      />
    </svg>
  );
};
