export enum SvgEnum {
  CLOSE_X = 'close-x',
  SECURITY_CHECK = 'security-check',
  CAMERA_BACKUP = 'camera-backup',
  STARK = 'stark',
  GUARD_SERVICE = 'guard-service',
  COMMUNITY_COMMITMENTS = 'community-commitments',
  NEIGHBORHOOD_NEW = 'neighborhood-new',
  MENU = 'menu',
  HOME_SECURITY_SYSTEMS = 'home-security-systems',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_DOWN = 'chevron-down',
  RVD = 'rvd',
  RING_PROTECT = 'ring-protect',
  BLOG = 'blog',
  INSTALLATION_SERVICES = 'installation-services',
  PROFESSIONAL_INSTALLATION = 'professional-installation',
  RING_FOR_BUSINESS = 'ring-for-business',
  HOME = 'home',
  HEADPHONES = 'headphones',
  CART = 'cart',
  FLOODLIGHT_CAM = 'floodlight-cam',
  CAMERA = 'camera',
  CAR_CAM = 'car-cam',
  LIGHT = 'light',
  CHIME = 'chime',
  PET_SETTINGS = 'pet-settings',
  SHOP_ALL_PRODUCTS = 'shop-all-products',
  OFFERS = 'offers',
  FIND = 'find',
  QUESTIONMARK_CIRCLE = 'questionmark-circle',
  SEARCH = 'search',
  RING_LOGO = 'ring-logo',
  PUBLIC_AGENCY = 'public-agency',
  NEIGHBORHOOD_WATCH = 'neighborhood-watch',
  RING_TV = 'ring-tv',
  BUNDLE = 'bundle',
  ASTRO_ROBOT = 'astro-robot',
  LOG_IN = 'log-in',
  LOG_OUT = 'log-out',
  GIFT = 'gift',
  BUSINESS_INSTALLATION = 'business-installation',
  GIVE_BACK_DISCOUNT = 'give-back-discount',
  BUSINESS_SOLUTIONS = 'business-solutions',
  AI_SEARCH = 'ai-search',
  PERSON_ALERTS = 'person-detection-fill',
  VIDEO_PREVIEW_ALERTS = 'video-preview-alerts',
  DOORBELL_CALLS = 'doorbell-calls',
  TWENTY_FOUR_SEVEN_REC = 'twenty-four-seven-rec',
  MULTI_CAM_LIVEVIEW = 'multi-cam-liveview',
  FLOOD_FREEZE_CALLS = 'flood-freeze-calls',
  SOS = 'sos',
  BACKUP_INTERNET = 'backup-internet',
  PRO_PROTECTION = 'pro-protection',
  SECURE_VIDEO_STORAGE = 'secure-video-storage',
  ALARM_PRO_MONITORING = 'alarm-pro-monitoring'
}

export interface SvgProps {
  fillColor?: string;
  className?: string;
  width?: number;
  height?: number;
  viewBox?: string;
}

export interface SvgRendererProps extends SvgProps {
  name: SvgEnum;
}
