import { SvgProps } from '../SvgRendererTypes';

export const BackupInternet = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" {...props}>
      <title>Backup Internet</title>
      <path
        className={fillColor}
        d="M21,16.5h0s0,0,0,0V5.51c0-.55-.45-1-1-1H4c-.55,0-1,.45-1,1v10.99s0,0,0,0h0c-.55,0-1,.45-1,1v1c0,.55.45,1,1,1h18c.55,0,1-.45,1-1v-1c0-.55-.45-1-1-1ZM4,16.5V5.5h16s0,11,0,11H4ZM21,18.5H3s0-1,0-1h18s0,1,0,1Z"
      />
      <circle cx="12.01" cy="14.26" r=".73" />
      <path
        className={fillColor}
        d="M9.6,12.04l.64.77c.38-.32.99-.7,1.76-.7.63,0,1.25.24,1.75.69l.66-.75c-.68-.61-1.54-.94-2.42-.94-1.07,0-1.9.51-2.41.93Z"
      />
      <path
        className={fillColor}
        d="M8.22,10.63l.68.73c.91-.85,2.01-1.32,3.1-1.32s2.17.47,3.11,1.32l.67-.74c-1.13-1.02-2.47-1.58-3.78-1.58s-2.69.56-3.79,1.58Z"
      />
      <path
        className={fillColor}
        d="M6.8,9.14l.71.7s1.86-1.85,4.49-1.85,4.46,1.82,4.48,1.84l.72-.7c-.08-.09-2.12-2.14-5.19-2.14s-5.12,2.06-5.21,2.15Z"
      />
    </svg>
  );
};
