import { SvgProps } from '../SvgRendererTypes';

export const MultiCamLiveview = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Font_Icons"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <title>Multi-Cam Live View</title>
      <g>
        <g>
          <path
            className={fillColor}
            d="M9.54,13H3.46C2.65,13,2,13.65,2,14.46v6.08C2,21.35,2.65,22,3.46,22h6.08c0.81,0,1.46-0.65,1.46-1.46v-6.08
			C11,13.65,10.35,13,9.54,13z M10,20.54C10,20.79,9.79,21,9.54,21H3.46C3.21,21,3,20.79,3,20.54v-6.08C3,14.21,3.21,14,3.46,14
			h6.08C9.79,14,10,14.21,10,14.46V20.54z"
          />
        </g>
        <g>
          <path
            className={fillColor}
            d="M20.54,13h-6.08C13.65,13,13,13.65,13,14.46v6.08c0,0.81,0.65,1.46,1.46,1.46h6.08c0.81,0,1.46-0.65,1.46-1.46v-6.08
			C22,13.65,21.35,13,20.54,13z M21,20.54c0,0.25-0.21,0.46-0.46,0.46h-6.08C14.21,21,14,20.79,14,20.54v-6.08
			c0-0.25,0.21-0.46,0.46-0.46h6.08c0.25,0,0.46,0.21,0.46,0.46V20.54z"
          />
        </g>
        <g>
          <path
            className={fillColor}
            d="M9.54,2H3.46C2.65,2,2,2.65,2,3.46v6.08C2,10.35,2.65,11,3.46,11h6.08C10.35,11,11,10.35,11,9.54V3.46
			C11,2.65,10.35,2,9.54,2z M10,9.54C10,9.79,9.79,10,9.54,10H3.46C3.21,10,3,9.79,3,9.54V3.46C3,3.21,3.21,3,3.46,3h6.08
			C9.79,3,10,3.21,10,3.46V9.54z"
          />
        </g>
        <g>
          <path
            className={fillColor}
            d="M16.48,10.55c0.57,0.57,1.49,0.57,2.06,0l3.03-3.03c0.57-0.57,0.57-1.49,0-2.06l-3.03-3.03c-0.57-0.57-1.49-0.57-2.06,0
			l-3.03,3.03c-0.57,0.57-0.57,1.49,0,2.06L16.48,10.55z"
          />
        </g>
      </g>
    </svg>
  );
};
