export const Defaults = {
  MARKETPLACE: 'US',
  LOCALE: 'en-US',
  LABELS: {
    LOGIN: 'Sign in',
    SIGN_UP: 'Sign up',
    NAVIGATION: 'Navigation',
    BACK: 'Back',
    CLOSE: 'Close',
    MENU: 'Menu',
    RING: 'Ring',
    DASHBOARD: 'Dashboard',
    HELP: 'Help',
    CART: 'Cart',
    FEATURED: 'Featured',
    SEARCH: 'Search',
    SHOP_ALL: 'Shop All',
    SHOP_DEALS: 'Shop Deals'
  }
};

export const RingWebsiteURLs: { [key: string]: string } = {
  alpha: 'https://www.dev.ring.com',
  beta: 'https://www.dev.ring.com',
  gamma: 'https://www.qa.ring.com',
  prod: 'https://ring.com'
};

export const FallbackLinks = (() => {
  const env = process.env.APP_STAGE || 'prod';
  const domain = RingWebsiteURLs[env];

  return {
    SIGN_IN: `${domain}/users/sign_in`,
    SIGN_UP: `${domain}/users/sign_up`,
    DASHBOARD: `${domain}/account/dashboard`,
    SUPPORT: `//support.ring.com`,
    CART: `${domain}/cart`,
    SEARCH: `${domain}/search`,
    SHOP_ALL: `${domain}/collections/all-products`,
    SHOP_DEALS: `${domain}/collections/offers`
  };
})();

export const BreakpointWidth = {
  LG: 1024,
  MD: 768
};

export enum Breakpoint {
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export const Cookies = {
  RS_GEO: 'rs_geo',
  CHOSEN_COUNTRY: 'chosen_country'
};

export const SESSION_STORAGE_CONTENT_KEY = 'gn_data';

export const MarketplaceConfigs: { [key: string]: string } = {
  alpha: 'https://nav.alpha.ring.com/marketplace',
  beta: 'https://nav.beta.ring.com/marketplace',
  gamma: 'https://nav.gamma.ring.com/marketplace',
  prod: 'https://nav.ring.com/marketplace'
};

export const GlobalNavContentURLs: { [key: string]: string } = {
  alpha: 'https://www.dev.ring.com/partials/{locale}/global-nav',
  beta: 'https://www.dev.ring.com/partials/{locale}/global-nav',
  gamma: 'https://www.qa.ring.com/partials/{locale}/global-nav',
  prod: 'https://ring.com/partials/{locale}/global-nav'
};

export enum Mode {
  NoContent = 'no_content'
}

export const NO_CONTENT_CONFIG = { menus: [], transactional: false };
