import { SvgProps } from '../SvgRendererTypes';

export const FloodFreezeCalls = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" {...props}>
      <title>Flood and Freeze Calls</title>
      <g>
        <g id="Font_Icons">
          <g>
            <path
              className={fillColor}
              d="M18.61,15.04l-1.41-.78,1.18-.59c.25-.12.35-.42.23-.67-.12-.25-.42-.35-.67-.23l-1.8.89-3.16-1.75,3.07-1.92,1.85.8c.06.03.13.04.2.04.19,0,.38-.11.46-.3.11-.25,0-.55-.26-.66l-1.21-.52,1.35-.85c.23-.15.3-.46.16-.69-.15-.23-.45-.3-.69-.16l-1.36.85.06-1.33c.01-.28-.2-.51-.48-.52-.28,0-.51.2-.52.48l-.09,2.02-3.03,1.9v-3.56l1.67-1.15c.23-.16.28-.47.13-.7-.16-.23-.47-.28-.7-.13l-1.1.76v-1.62c0-.28-.22-.5-.5-.5s-.5.22-.5.5v1.61l-1.08-.75c-.22-.16-.54-.1-.7.13-.16.23-.1.54.13.7l1.65,1.14v3.6l-3.17-1.75-.2-2c-.03-.27-.28-.47-.55-.45-.27.03-.48.27-.45.55l.13,1.31-1.39-.77c-.24-.13-.55-.05-.68.2-.13.24-.05.55.2.68l1.4.78-1.2.59c-.25.12-.35.42-.23.67.09.18.26.28.45.28.08,0,.15-.02.22-.05l1.82-.9,3.17,1.75-3.06,1.91-1.86-.8c-.25-.11-.55,0-.66.26-.11.25,0,.55.26.66l1.22.53-1.37.86c-.23.15-.3.46-.16.69.09.15.26.23.42.23.09,0,.18-.02.27-.08l1.36-.85-.06,1.32c-.01.28.2.51.48.52,0,0,.02,0,.02,0,.27,0,.49-.21.5-.48l.09-2.01,3.52-2.2s.02,0,.02,0l3.63,2.01.2,2.02c.03.26.24.45.5.45.02,0,.03,0,.05,0,.27-.03.48-.27.45-.55l-.13-1.33,1.41.78c.08.04.16.06.24.06.18,0,.35-.09.44-.26.13-.24.05-.55-.2-.68Z"
            />
            <path
              className={fillColor}
              d="M14.25,16.31c-.54-1.05-1.28-1.93-2.01-2.73-.08-.08-.16-.13-.24-.13-.09,0-.16.05-.2.09-.68.74-1.39,1.56-1.92,2.53-.22.4-.37.75-.37,1.14,0,.74.34,1.44.92,1.91.45.36,1.01.55,1.58.55.17,0,.34-.02.5-.05.75-.15,1.4-.66,1.74-1.35.31-.64.31-1.35,0-1.96h0ZM13.35,17.83c-.2.42-.59.72-1.04.81-.1.02-.21.03-.31.03-.34,0-.68-.12-.95-.33-.35-.28-.55-.69-.55-1.13,0-.15.07-.34.25-.66.35-.63.79-1.2,1.25-1.74.51.59.99,1.23,1.36,1.95.17.32.16.71,0,1.07Z"
            />
            <path
              className={fillColor}
              d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10,10-4.48,10-10S17.52,2,12,2ZM12,21c-4.96,0-9-4.04-9-9S7.04,3,12,3s9,4.04,9,9-4.04,9-9,9Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
