import { SvgProps } from '../SvgRendererTypes';

export const DoorbellCalls = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
      <title>Doorbell Calls</title>
      <g>
        <g id="Font_Icons">
          <g>
            <path
              className={fillColor}
              d="M14.24,2h-4.49c-1.81,0-3.28,1.46-3.28,3.27v13.47c0,1.8,1.47,3.27,3.28,3.27h4.49c1.81,0,3.28-1.46,3.28-3.27V5.27c0-1.8-1.47-3.27-3.28-3.27ZM16.52,18.73c0,1.25-1.02,2.26-2.27,2.26h-4.49c-1.25,0-2.27-1.02-2.27-2.26V5.27c0-1.25,1.02-2.26,2.27-2.26h4.49c1.25,0,2.27,1.02,2.27,2.26v13.47Z"
            />
            <path
              className={fillColor}
              d="M10.05,4.38h0c0,.28.23.51.51.51h2.95c.28,0,.51-.23.51-.51h0c0-.28-.23-.51-.51-.51h-2.95c-.28,0-.51.23-.51.51h0Z"
            />
            <path
              className={fillColor}
              d="M14.59,10.99c0-1.43-1.15-2.59-2.57-2.59s-2.57,1.16-2.57,2.59v1.85l-.65,1.85h2.28c.03.5.44.9.95.9s.92-.4.95-.9h2.28l-.67-1.85v-1.85ZM12.98,13.69h-.95v.1s-.01-.1-.01-.1h-1.8l.18-.52.06-.16v-2.02c0-.87.7-1.59,1.56-1.59s1.56.71,1.56,1.59v2.02l.06.16.18.51h-.85Z"
            />
            <path
              className={fillColor}
              d="M5.41,9.27c.12-.25.01-.55-.24-.67-.25-.12-.55-.01-.67.23-.05.11-1.26,2.67-.01,5.79.08.2.27.32.47.32.06,0,.12-.01.19-.04.26-.1.38-.39.28-.65-1.08-2.69-.02-4.96-.01-4.98Z"
            />
            <path
              className={fillColor}
              d="M3.89,7.19c.12-.25.01-.55-.24-.67-.25-.12-.55-.02-.67.23-.09.18-2.16,4.57-.01,9.93.08.2.27.32.47.32.06,0,.12-.01.19-.04.26-.1.38-.39.28-.65-1.97-4.94-.03-9.08-.01-9.12Z"
            />
            <path
              className={fillColor}
              d="M19.51,8.86c-.1-.26-.4-.39-.65-.28-.26.1-.38.39-.28.65,1.08,2.7.02,4.96.01,4.98-.12.25-.01.55.24.67.07.03.14.05.22.05.19,0,.37-.1.45-.28.05-.11,1.26-2.67.01-5.79Z"
            />
            <path
              className={fillColor}
              d="M21.03,6.79c-.1-.26-.4-.38-.65-.28-.26.1-.38.39-.28.65,1.97,4.94.03,9.08.01,9.12-.12.25-.01.55.24.67.07.03.15.05.22.05.19,0,.37-.1.45-.28.09-.18,2.16-4.57.01-9.93Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
