import { SvgProps } from '../SvgRendererTypes';

export const AISearch = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg
      data-name="Font Icons"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 24 24"
      {...props}
    >
      <title>Ai Search</title>
      <path
        fill={fillColor}
        d="M2.32,7.66s3.3.49,4.46,4.42c.05.18.2.3.38.3h0c.17,0,.31-.11.35-.28.43-1.62,1.56-3.71,4.45-4.45.18-.05.3-.2.3-.38,0-.17-.11-.31-.27-.35-3.44-.67-4.49-4.56-4.5-4.59-.04-.18-.19-.3-.37-.31-.16,0-.32.11-.36.28-.92,3.98-4.32,4.62-4.4,4.64-.18.05-.3.2-.3.38,0,.3.26.34.26.34Z"
      />
      <path
        fill={fillColor}
        d="M11.98,9.21h0c-.11,0-.19.07-.22.17h0c-.21.75-.71,1.71-1.92,2.02-.11.03-.18.12-.18.23s.07.2.17.23c.71.2,1.62.69,1.94,1.92.03.11.13.19.24.19h0c.11,0,.2-.07.22-.17.19-.7.68-1.61,1.94-1.93.11-.03.18-.12.19-.24,0-.11-.07-.2-.17-.23-.72-.19-1.64-.69-1.96-2-.03-.11-.12-.19-.23-.19,0,0-.02,0-.02,0Z"
      />
      <path
        fill={fillColor}
        d="M21.68,19.41h0c-.19-.19-4.36-4.34-4.98-4.95.88-1.32,1.35-2.85,1.35-4.43,0-4.42-3.6-8.02-8.02-8.02-.45,0-.91.05-1.36.13.06.23.2.75.4,1.19.33-.05.65-.08.96-.08,3.74,0,6.79,3.05,6.79,6.79s-3.05,6.79-6.79,6.79-6.79-3.05-6.79-6.79c0-.18.03-.38.05-.58-.43-.28-1.01-.4-1.21-.44-.04.34-.08.68-.08,1.01,0,4.42,3.6,8.02,8.02,8.02,1.59,0,3.12-.46,4.43-1.34.59.6,4.19,4.2,4.95,4.97.21.21.49.32.78.32s.57-.11.78-.32l.71-.71c.21-.21.32-.49.32-.78s-.11-.57-.32-.78h0ZM20.19,20.72l-4.71-4.7.53-.53,4.71,4.7-.52.53s-.01,0-.01,0Z"
      />
    </svg>
  );
};
