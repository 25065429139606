import { SvgProps } from '../SvgRendererTypes';

export const SecureVideoStorage = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" {...props}>
      <title>Secure Video Storage Icon</title>
      <path
        className={fillColor}
        d="M15,2.15c-.09-.09-.22-.15-.35-.15H6c-.55,0-1,.45-1,1v18c0,.55.45,1,1,1h12c.55,0,1-.45,1-1V6.35c0-.13-.05-.26-.15-.35l-3.85-3.85ZM18,21H6V3h1.46v3.52c0,.28.22.5.5.5s.5-.22.5-.5v-3.52h1v3.52c0,.28.22.5.5.5s.5-.22.5-.5v-3.52h1v3.52c0,.28.22.5.5.5s.5-.22.5-.5v-3.52h1v3.52c0,.28.22.5.5.5s.5-.22.5-.5v-3.49l3.54,3.54v14.44Z"
      />
      <path
        className={fillColor}
        d="M10.16,10.4c-.31-.19-.57-.09-.57.23v5.75c0,.32.25.42.57.23l4.53-2.76c.31-.19.31-.5,0-.69l-4.53-2.76Z"
      />
    </svg>
  );
};
