import { SvgProps } from '../SvgRendererTypes';

export const VideoPreviewAlerts = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" {...props}>
      <title>Video Preview Alerts</title>
      <circle cx="12" cy="19" r=".75" />
      <path
        className={fillColor}
        d="M17.5,15c-.28,0-.5.22-.5.5v5.5H7V3h10v.5c0,.28.22.5.5.5s.5-.22.5-.5v-.5c0-.55-.45-1-1-1H7c-.55,0-1,.45-1,1v18c0,.55.45,1,1,1h10c.55,0,1-.45,1-1v-5.5c0-.28-.22-.5-.5-.5Z"
      />
      <path
        className={fillColor}
        d="M20,5h-10c-.55,0-1,.45-1,1v7c0,.55.45,1,1,1h2v3l3.8-3h4.2c.55,0,1-.45,1-1v-7c0-.55-.45-1-1-1ZM20,13h-4.57l-2.43,2v-2h-3v-7h10v7Z"
      />
      <path
        className={fillColor}
        d="M13.94,11.34l2.64-1.62c.2-.13.21-.42,0-.55l-2.64-1.65c-.22-.13-.49.02-.49.27v3.27c0,.25.28.41.49.28Z"
      />
    </svg>
  );
};
