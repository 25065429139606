import { SvgEnum, SvgProps, SvgRendererProps } from './SvgRendererTypes';
import { BlogIcon } from './svg-components/BlogIcon';
import { CameraIcon } from './svg-components/CameraIcon';
import { CameraBackupIcon } from './svg-components/CameraBackupIcon';
import { CarCamIcon } from './svg-components/CarCamIcon';
import { CartIcon } from './svg-components/CartIcon';
import { ChevronDownIcon } from './svg-components/ChevronDownIcon';
import { ChevronLeftIcon } from './svg-components/ChevronLeftIcon';
import { ChevronRightIcon } from './svg-components/ChevronRightIcon';
import { ChimeIcon } from './svg-components/ChimeIcon';
import { CloseXIcon } from './svg-components/CloseXIcon';
import { CommunityCommitmentsIcon } from './svg-components/CommunityCommitmentsIcon';
import { FindIcon } from './svg-components/FindIcon';
import { FloodlightCamIcon } from './svg-components/FloodlightCamIcon';
import { GuardServiceIcon } from './svg-components/GuardServiceIcon';
import { HeadphonesIcon } from './svg-components/HeadphonesIcon';
import { HomeIcon } from './svg-components/HomeIcon';
import { HomeSecuritySystemsIcon } from './svg-components/HomeSecuritySystemsIcon';
import { InstallationServicesIcon } from './svg-components/InstallationServicesIcon';
import { LightIcon } from './svg-components/LightIcon';
import { MenuIcon } from './svg-components/MenuIcon';
import { NeighborhoodNewIcon } from './svg-components/NeighborhoodNewIcon';
import { OffersIcon } from './svg-components/OffersIcon';
import { PetSettingsIcon } from './svg-components/PetSettingsIcon';
import { ProfessionalInstallationIcon } from './svg-components/ProfessionalInstallationIcon';
import { QuestionmarkCircleIcon } from './svg-components/QuestionmarkCircleIcon';
import { RingForBusiness } from './svg-components/RingForBusinessIcon';
import { RingProtectIcon } from './svg-components/RingProtectIcon';
import { RvdIcon } from './svg-components/RvdIcon';
import { SearchIcon } from './svg-components/SearchIcon';
import { SecurityCheckIcon } from './svg-components/SecurityCheckIcon';
import { ShopAllProductsIcon } from './svg-components/ShopAllProductsIcon';
import { StarkIcon } from './svg-components/StarkIcon';
import { RingLogo } from './svg-components/RingLogo';
import { RingTvIcon } from './svg-components/RingTvIcon';
import { PublicAgencyIcon } from './svg-components/PublicAgencyIcon';
import { NeighborhoodWatchIcon } from './svg-components/NeighborhoodWatchIcon';
import { BundleIcon } from './svg-components/BundleIcon';
import { AstroRobotIcon } from './svg-components/AstroRobotIcon';
import { LogInIcon } from './svg-components/LogInIcon';
import { LogOutIcon } from './svg-components/LogOutIcon';
import { GiftIcon } from './svg-components/GiftIcon';
import { BusinessInstallationIcon } from './svg-components/BusinessInstallationIcon';
import { GiveBackDiscountIcon } from './svg-components/GiveBackDiscountIcon';
import { BusinessSolutionsIcon } from './svg-components/BusinessSolutions';
import { AISearch } from './svg-components/AISearch';

import styleVars from '../../styles/_variables.module.scss';
import { PersonDetectionFill } from './svg-components/PersonDetectionFill';
import { VideoPreviewAlerts } from './svg-components/VideoPreviewAlerts';
import { DoorbellCalls } from './svg-components/DoorbellCalls';
import { TwentyFourSevenRec } from './svg-components/TwentyFourSevenRec';
import { MultiCamLiveview } from './svg-components/MultiCamLiveview';
import { FloodFreezeCalls } from './svg-components/FloodFreezeCalls';
import { SOS } from './svg-components/SOS';
import { BackupInternet } from './svg-components/BackupInternet';
import { ProProtection } from './svg-components/ProProtection';
import { SecureVideoStorage } from './svg-components/SecureVideoStorage';
import { AlarmProMonitoring } from './svg-components/AlarmProMonitoring';

export const svgComponentMap: { [key in SvgEnum]: (props: SvgProps) => JSX.Element } = {
  [SvgEnum.CLOSE_X]: CloseXIcon,
  [SvgEnum.SECURITY_CHECK]: SecurityCheckIcon,
  [SvgEnum.CAMERA_BACKUP]: CameraBackupIcon,
  [SvgEnum.STARK]: StarkIcon,
  [SvgEnum.GUARD_SERVICE]: GuardServiceIcon,
  [SvgEnum.COMMUNITY_COMMITMENTS]: CommunityCommitmentsIcon,
  [SvgEnum.NEIGHBORHOOD_NEW]: NeighborhoodNewIcon,
  [SvgEnum.MENU]: MenuIcon,
  [SvgEnum.HOME_SECURITY_SYSTEMS]: HomeSecuritySystemsIcon,
  [SvgEnum.CHEVRON_RIGHT]: ChevronRightIcon,
  [SvgEnum.CHEVRON_LEFT]: ChevronLeftIcon,
  [SvgEnum.CHEVRON_DOWN]: ChevronDownIcon,
  [SvgEnum.RVD]: RvdIcon,
  [SvgEnum.RING_PROTECT]: RingProtectIcon,
  [SvgEnum.BLOG]: BlogIcon,
  [SvgEnum.INSTALLATION_SERVICES]: InstallationServicesIcon,
  [SvgEnum.PROFESSIONAL_INSTALLATION]: ProfessionalInstallationIcon,
  [SvgEnum.RING_FOR_BUSINESS]: RingForBusiness,
  [SvgEnum.HOME]: HomeIcon,
  [SvgEnum.HEADPHONES]: HeadphonesIcon,
  [SvgEnum.CART]: CartIcon,
  [SvgEnum.FLOODLIGHT_CAM]: FloodlightCamIcon,
  [SvgEnum.CAMERA]: CameraIcon,
  [SvgEnum.CAR_CAM]: CarCamIcon,
  [SvgEnum.LIGHT]: LightIcon,
  [SvgEnum.CHIME]: ChimeIcon,
  [SvgEnum.PET_SETTINGS]: PetSettingsIcon,
  [SvgEnum.SHOP_ALL_PRODUCTS]: ShopAllProductsIcon,
  [SvgEnum.OFFERS]: OffersIcon,
  [SvgEnum.FIND]: FindIcon,
  [SvgEnum.QUESTIONMARK_CIRCLE]: QuestionmarkCircleIcon,
  [SvgEnum.SEARCH]: SearchIcon,
  [SvgEnum.RING_LOGO]: RingLogo,
  [SvgEnum.RING_TV]: RingTvIcon,
  [SvgEnum.PUBLIC_AGENCY]: PublicAgencyIcon,
  [SvgEnum.NEIGHBORHOOD_WATCH]: NeighborhoodWatchIcon,
  [SvgEnum.BUNDLE]: BundleIcon,
  [SvgEnum.ASTRO_ROBOT]: AstroRobotIcon,
  [SvgEnum.LOG_IN]: LogInIcon,
  [SvgEnum.LOG_OUT]: LogOutIcon,
  [SvgEnum.GIFT]: GiftIcon,
  [SvgEnum.BUSINESS_INSTALLATION]: BusinessInstallationIcon,
  [SvgEnum.GIVE_BACK_DISCOUNT]: GiveBackDiscountIcon,
  [SvgEnum.BUSINESS_SOLUTIONS]: BusinessSolutionsIcon,
  [SvgEnum.AI_SEARCH]: AISearch,
  [SvgEnum.PERSON_ALERTS]: PersonDetectionFill,
  [SvgEnum.VIDEO_PREVIEW_ALERTS]: VideoPreviewAlerts,
  [SvgEnum.DOORBELL_CALLS]: DoorbellCalls,
  [SvgEnum.TWENTY_FOUR_SEVEN_REC]: TwentyFourSevenRec,
  [SvgEnum.MULTI_CAM_LIVEVIEW]: MultiCamLiveview,
  [SvgEnum.FLOOD_FREEZE_CALLS]: FloodFreezeCalls,
  [SvgEnum.SOS]: SOS,
  [SvgEnum.BACKUP_INTERNET]: BackupInternet,
  [SvgEnum.PRO_PROTECTION]: ProProtection,
  [SvgEnum.SECURE_VIDEO_STORAGE]: SecureVideoStorage,
  [SvgEnum.ALARM_PRO_MONITORING]: AlarmProMonitoring
};

const svgDefaults = {
  WIDTH: 24,
  HEIGHT: 24,
  VIEWBOX: '0 0 24 24',
  FILL_COLOR: `var(--gn-svg-fill, ${styleVars.NEUTRAL_500})`
};

export const SvgRenderer = ({ fillColor, ...props }: SvgRendererProps) => {
  const name = props.name.toLowerCase() as SvgEnum;
  const Component = svgComponentMap[name];

  return Component ? (
    <Component
      className={props.className}
      width={props.width || svgDefaults.WIDTH}
      height={props.height || svgDefaults.HEIGHT}
      viewBox={props.viewBox || svgDefaults.VIEWBOX}
      fillColor={fillColor || svgDefaults.FILL_COLOR}
    />
  ) : null;
};
