import { SvgProps } from '../SvgRendererTypes';

export const TwentyFourSevenRec = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <title>24/7 Recording</title>
      <g>
        <path
          className={fillColor}
          d="M7.21,14.73c0.69,0,1.39-0.26,1.91-0.78c0.02-0.02,0.46-0.43,0.9-1.03c0.45,0.61,0.86,1,0.88,1.02
			c1.06,1.06,2.79,1.06,3.86,0c1.06-1.06,1.06-2.79,0-3.86c-1.06-1.06-2.79-1.06-3.85-0.01c-0.07,0.06-0.48,0.46-0.9,1.02
			c-0.45-0.61-0.86-1-0.88-1.02c-1.06-1.06-2.79-1.06-3.86,0c-1.06,1.06-1.06,2.79,0,3.86C5.81,14.46,6.51,14.73,7.21,14.73z
			M11.59,10.79l0.01-0.01c0.67-0.67,1.77-0.67,2.44,0c0.67,0.67,0.67,1.77,0,2.44c-0.67,0.67-1.77,0.67-2.45-0.01
			c-0.01-0.01-0.53-0.51-1-1.22C11.04,11.31,11.58,10.8,11.59,10.79z M5.98,10.78c0.34-0.34,0.78-0.5,1.22-0.5
			c0.44,0,0.89,0.17,1.23,0.51c0.01,0.01,0.53,0.51,1,1.22c-0.44,0.69-0.98,1.2-1,1.21c-0.67,0.67-1.77,0.67-2.44,0
			C5.31,12.55,5.31,11.45,5.98,10.78z"
        />
        <path
          className={fillColor}
          d="M21.5,7.5c-0.1,0-0.22,0.05-0.3,0.1L18,9.5v-2.5c0-0.5-0.4-1-1-1H3c-0.6,0-1,0.5-1,1v10.01c0,0.5,0.4,1,1,1h14
			c0.6,0,1-0.5,1-1v-2.5l3.2,1.9c0.08,0.03,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.5-0.5V8C22,7.7,21.8,7.5,21.5,7.5z M17,10.1v6.91H3V6.99
			h14V10.1z M21,15.1l-3-1.7v-2.8l3-1.7V15.1z"
        />
      </g>
    </svg>
  );
};
